<template>
    <div v-if="isMobileMode">
        <div style="text-align: center">
            <img :src="require('@/assets/image/logo.webp')" alt="" width="150" height="45" />
        </div>
        <div class="div_bottom_V">
            <div style="text-align: center" v-if="beianVisible">
                <img :src="require('@/assets/image/beian.png')" alt="" style="
                            display: inline-block;
                            vertical-align: middle;
                            width: 8px;
                            height: 8px;
                        " />
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035535" target="_blank"
                    style="display: inline-block; vertical-align: middle">闽公网安备 35020302035535号</a>
            </div>
            <div style="text-align: center" v-if="beianVisible">
                <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022019100号-1</a>
            </div>
        </div>
    </div>
    <div class="div_bottom_H" v-else>
        <span>Powered By Autopos Technology Ltd.</span>
        <div class="div_beian" v-if="beianVisible">
            <span>|</span>
            <img :src="require('@/assets/image/beian.png')" alt="" style="width: 14px; height: 14px" />
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035535" target="_blank">
                闽公网安备 35020302035535号
            </a>
            <span>|</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022019100号-1</a>
        </div>
    </div>
</template>

<script>
import { isMobile } from "@/util/util";
export default {
    name: "BottomBar",
    componentName: "BottomBar",
    props: {
        vertical: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMobileMode: false,
            beianVisible: false,
        };
    },
    mounted() {
        this.isMobileMode = isMobile();
        this.beianVisible = window.location.hostname.includes("autopos.cn") || window.location.host.includes("localhost");
    },
};
</script>
<style lang="less" scoped>
.div_bottom_H {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
}

.div_bottom_V {
    width: 100%;
    font-size: 9px;
}

.div_beian {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 10px;

    & span {
        margin: 0px 10px;
    }
}
</style>