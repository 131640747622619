export default {
  app: {
    orgCode: "グループ/ブランドコード",
    orgCodePlaceholder: "グループ/ブランドコードを入力してください",
    username: "アカウント",
    usernamePlaceholder: "アカウントを入力してください",
    password: "パスワード",
    passwordPlaceholder: "パスワードを入力してください",
    captcha: "認証コード",
    captchaPlaceholder: "認証コードを入力してください",
    captchaExpired: "認証コードを更新してください。",
    login: "ログイン",
    remember: "ログイン情報を記憶する",
    switchPC: "バックエンドの切り替え",
    setupPassword: "パスワード設定",
    newPassword: "新しいパスワード",
    newPasswordPlaceholder: "新しいパスワードを入力してください",
    retypePassword: "新しいパスワードの再入力",
    retypePasswordPlaceholder: "新しいパスワードを再度入力してください",
    setupPasswordRule: "パスワードは6-32文字とし、数字、アルファベット、記号から2種類以上を組み合わせるものとします。",
    setupPasswordRuleRefer: "パスワードは6-32文字とし、数字、アルファベット、記号から2種類以上を組み合わせるものとします。再度入力してください。",
    setupPasswordOk: "確定",
    setupPasswordDiffer: "2回入力したパスワードが一致しないため、再度入力してください。",
    err_def: "ログインできないため、ページを再読み込みしてから再度お試しください。",
    err_2000: "入力した認証コードが正しくありません。",
    err_2010: "入力したログイン情報が正しくありません。",
    err_4001: "このアカウントにはどの機能も許可されていません。",
    err_4011: "店舗はすでに利用停止となっているため、ログインできません。",
    err_4012: "加盟店はすでに利用停止となっているため、ログインできません。",
    err_4013: "ブランドはすでに利用停止となっているため、ログインできません。",
    err_4014: "ご指定のグループはすでに利用停止となっているため、ログインできません。",
    err_4015: "ご指定のエリアはすでに利用停止となっているため、ログインできません。",
    err_5001: "パスワード設定に失敗したため、再度お試しください。",
  },
}