<template>
    <div class="div_home">
        <TitleBar ref="titleBar" :titleText="$t('app.login')" :titleVisible="isMobileMode" />
        <!-- 移动端 -->
        <div class="div_center" v-if="isMobileMode">
            <div class="div_center_mobile">
                <login-pane class="login_pane_mobile" ref="loginPane" />
            </div>
            <BottomBar />
        </div>
        <!-- PC端 -->
        <div class="div_center" v-else>
            <div class="div_center_pc">
                <img class="bg" :src="require('@/assets/image/bg.webp')" alt="" />
                <login-pane class="login_pane_pc" ref="loginPane" />
            </div>
            <BottomBar />
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import BottomBar from "@/components/BottomBar.vue";
import LoginPane from "../home/LoginPane.vue";
import { getLanguageArray, getLang } from "@/lang/index";
import { isMobile } from "@/util/util";
export default {
    name: "Home",
    components: {
        TitleBar,
        BottomBar,
        LoginPane,
    },
    data() {
        return {
            isMobileMode: false,
            languageArray: getLanguageArray(),
        };
    },
    mounted() {
        this.isMobileMode = isMobile();

        // 判断是否为登录验证
        let verifySubject = this.$route.query["vs"];
        if (verifySubject) {
            let verifyContent = this.$route.query["vc"];
            this.$refs.loginPane.setLoginVerify(verifySubject, verifyContent);
        }
    },
};
</script>
<style lang="less" scoped>
.div_home {
    display: flex;
    flex-direction: column;
    width: 96%;
    height: 96vh;
    margin: 10px;
}

.div_center {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.div_center_pc {
    width: 620px;
    height: 340px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 16px;
    font-size: 9px;

    & .bg {
        width: 310px;
        height: 340px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    & .login_pane_pc {
        width: 100%;
        margin: 10px 16px 10px 10px;

        /deep/.van-field__control {
            font-size: 10px;
        }

        /deep/.van-field__left-icon {
            margin-top: 1px;
        }
    }

}

.div_center_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 16px;
    margin: 10px;

    & .login_pane_mobile {
        width: 100%;
        margin: 6px 16px 10px 16px;

        /deep/.van-field__left-icon {
            margin-top: 5px;
        }
    }
}
</style>