<template>
    <div class="div-login">
        <!-- 编号 -->
        <div class="div-row">
            <div>{{ $t("app.orgCode") }}</div>
            <van-field class="text-input" ref="inputOrgCode" maxlength="8" type="digit" autosize clearable
                v-model="orgCode" :placeholder="$t('app.orgCodePlaceholder')" @keydown.enter="focusToUsername()">
                <template #left-icon>
                    <img src="@/assets/image/org.png" alt="" width="16px" height="16px" />
                </template>
            </van-field>
        </div>
        <!-- 账号 -->
        <div class="div-row">
            <div>{{ $t("app.username") }}</div>
            <van-field class="text-input" ref="inputUsername" maxlength="16" autosize clearable v-model="username"
                :placeholder="$t('app.usernamePlaceholder')" @keydown.enter="focusToPassword()">
                <template #left-icon>
                    <img src="@/assets/image/username.png" alt="" width="16px" height="16px" />
                </template>
            </van-field>
        </div>
        <!-- 密码 -->
        <div class="div-row">
            <div>{{ $t("app.password") }}</div>
            <password-field ref="inputPassword" v-model="password" :placeholder="$t('app.passwordPlaceholder')"
                @enter="focusToCaptcha()" />
        </div>
        <!-- 图形验证码 -->
        <div class="div-row">
            <div>{{ $t("app.captcha") }}</div>
            <div class="div-captcha">
                <van-field class="text-input" ref="inputCaptcha" maxlength="4" type="digit" autosize clearable
                    v-model="captchaInput" :placeholder="$t('app.captchaPlaceholder')" @keydown.enter="submitLogin()">
                    <template #left-icon>
                        <img src="@/assets/image/captcha.png" alt="" width="16px" height="16px" />
                    </template>
                </van-field>
                <img class="img-captcha" :src="captchaImage" alt="" @click="refreshCaptcha()" v-if="!captchaExpired" />
                <img class="img-captcha" src="@/assets/image/captcha_expired.png" alt="" @click="refreshCaptcha()"
                    v-if="captchaExpired" />
            </div>
        </div>
        <!-- 登录 -->
        <div class="div-login">
            <van-button type="primary" style="width: 100%" @click="submitLogin()">{{ $t("app.login") }}</van-button>
        </div>
        <div class="div-control">
            <div class="div-remember">
                <input type="checkbox" id="remember-checkbox" v-model="isRemember" />
                <label for="remember-checkbox">{{ $t("app.remember") }}</label>
            </div>
        </div>

        <password-setup-dialog v-if="passwordSetupVisible" @cancel="onLoginFailed" @submit="onLoginSuccess"
            :permit="passwordSetupPermit" :user="passwordSetupUser" />
    </div>
</template>

<script>
import PasswordField from "@/components/PasswordField.vue";
import PasswordSetupDialog from "./PasswordSetupDialog.vue";
import { saltPassword } from '@/util/md5';
import { getCaptcha, login, loginVerify, } from "@/api/api";
import { getCookie, removeCookie, setCookie } from "@/util/util";

export default {
    name: "LoginPane",
    componentName: "LoginPane",
    components: {
        PasswordField,
        PasswordSetupDialog,
    },
    data() {
        return {
            orgCode: "",
            username: "",
            password: "",
            passwordVisible: false,
            captchaInput: "",
            captchaPid: "",
            captchaImage: "",
            rememberPassword: "",
            isRemember: false,
            timer: null,
            captchaExpired: false,
            passwordSetupVisible: false,
            passwordSetupPermit: "",
            passwordSetupUser: "",
            loginVerifyInfo: null,
        };
    },
    mounted() {
        this.orgCode = getCookie("login_orgCode");
        this.username = getCookie("login_username");
        this.rememberPassword = getCookie("login_password");
        if (this.rememberPassword && this.rememberPassword.trim().length > 0) {
            this.password = this.rememberPassword;
            this.$refs.inputPassword.setValue(this.password);
            this.isRemember = true;
            this.focusToCaptcha();
        } else {
            this.password = "";
            this.$refs.inputPassword.setValue("");
            this.isRemember = false;
            this.focusToOrgCode();
        }
        this.refreshCaptcha();
    },
    methods: {
        focusToOrgCode() {
            this.$refs.inputOrgCode.focus();
        },
        focusToUsername() {
            this.$refs.inputUsername.focus();
        },
        focusToPassword() {
            this.$refs.inputPassword.focus();
        },
        focusToCaptcha() {
            this.$refs.inputCaptcha.focus();
        },
        startCaptchaExpire() {
            this.captchaExpired = false;
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.timer = setInterval(() => {
                this.captchaExpired = true;
                clearInterval(this.timer);
                this.timer = null;
            }, 1000 * 150);
        },
        refreshCaptcha() {
            getCaptcha().then((rtn) => {
                if (rtn.code === 200) {
                    this.captchaPid = rtn.data.pid;
                    this.captchaImage = rtn.data.imageSrc;
                    this.startCaptchaExpire();
                } else {
                    let msg = this.$t("app.err_" + rtn.code);
                    this.$toast({
                        message: msg,
                        className: "inexa",
                        position: "top",
                    });
                }
            });
        },
        setLoginVerify(verifySubject, verifyContent) {
            this.loginVerifyInfo = {
                subject: verifySubject,
                content: verifyContent,
            };
        },
        submitLogin() {
            if (!this.orgCode || this.orgCode.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.orgCodePlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToOrgCode();
                return;
            }
            if (!this.username || this.username.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.usernamePlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToUsername();
                return;
            }
            if (!this.password || this.password.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.passwordPlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToPassword();
                return;
            }
            if (this.captchaExpired) {
                this.$toast({
                    message: this.$t("app.captchaExpired"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToCaptcha();
                return;
            }
            if (!this.captchaInput || this.captchaInput.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.captchaPlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToCaptcha();
                return;
            }

            // 对明文密码进行摘要处理
            var tempRemember;
            var tempPwd;
            if (this.password === this.rememberPassword) {
                tempRemember = true;
                tempPwd = this.password;
            } else {
                tempRemember = false;
                tempPwd = saltPassword(this.password);
            }

            var params = {
                orgCode: this.orgCode,
                username: this.username,
                password: tempPwd,
                captchaPid: this.captchaPid,
                captchaInput: this.captchaInput,
                remember: tempRemember,
                lang: this.$i18n.locale,
            };
            if (this.loginVerifyInfo) {
                params.subject = this.loginVerifyInfo.subject;
                params.content = this.loginVerifyInfo.content;
                loginVerify(params).then(this.handleLoginRes);
            } else {
                login(params).then(this.handleLoginRes);
            }
        },
        handleLoginRes(rtn) {
            if (rtn.code === 200) {
                if (rtn.data.href == null) {
                    this.passwordSetupPermit = rtn.data.setupPasswordPermit;
                    this.passwordSetupUser = rtn.data.setupPasswordUser;
                    this.passwordSetupVisible = true;
                } else {
                    this.onLoginSuccess(rtn.data);
                }
            } else {
                let msg = this.$t("app.err_" + rtn.code);
                if (msg.startsWith("app.err_")) {
                    msg = this.$t("app.err_def");
                }
                this.$toast({
                    message: msg,
                    className: "inexa",
                    position: "top",
                });
                this.onLoginFailed();
            }
        },
        onLoginFailed() {
            this.passwordSetupPermit = "";
            this.passwordSetupUser = "";
            this.passwordSetupVisible = false;
            this.refreshCaptcha();
        },
        onLoginSuccess(data) {
            this.passwordSetupPermit = "";
            this.passwordSetupUser = "";
            this.passwordSetupVisible = false;
            if (this.isRemember) {
                this.rememberPassword = data.rememberPassword;
                this.password = this.rememberPassword;
                setCookie("login_password", this.rememberPassword, data.rememberExpireDays);
                setCookie("login_username", this.username, data.rememberExpireDays);
                setCookie("login_orgCode", this.orgCode, data.rememberExpireDays);
            } else {
                removeCookie("login_orgCode");
                removeCookie("login_username");
                removeCookie("login_password");
            }
            console.log("login success : " + data.href);
            window.open(data.href, "_self");
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
};
</script>
<style lang="less" scoped>
.van-button {
    border-radius: 2px;
    height: 1rem;
}

.div-row {
    margin-top: 10px;
    margin-bottom: 0px;
}

.van-cell {
    margin-top: 2px;
    padding: 1px 5px;
}

.van-field {
    align-items: center;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #b2b2b2;
}

.van-field:hover:not(focus-within) {
    background-color: #f5f5f5;
    border: 1px solid #adadad;
}

.van-field:focus-within {
    outline: 1px solid #6e6bd7;
}

.div-captcha {
    display: flex;
    justify-content: space-between;
}

.img-captcha {
    width: 140px;
    height: 0.8rem;
}

.div-login {
    margin-top: 25px;
}

.div-control {
    display: flex;
    justify-content: space-between;
    height: 0.8rem;
}

.div-control .div-remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.div-control .div-switch {
    display: flex;
    text-align: right;
    align-self: center;
    align-items: center;
}

.button-switch {
    border-color: transparent;
    background: transparent;
    font-size: 13px;
}
</style>