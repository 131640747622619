<template>
    <div class="div_title">
            <div class="left">
                <div style="font-size: 18px" v-if="titleVisible">
                    {{ titleText }}
                </div>
            </div>
            <div class="right">
                <img :src="require('@/assets/image/country.webp')" alt="" />
                <select v-model="selectedLang" @change="onChangedLanguage">
                    <option :value="language.lang" v-for="language in languageArray" :key="language.lang">
                        {{ language.name }}
                    </option>
                </select>
                <svg viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet" focusable="false" style="
                        pointer-events: none;
                        display: block;
                        width: 16px;
                        height: 16px;
                    ">
                    <g>
                        <path
                            d="M399 275c-16-18-15-46 3-63 18-16 46-15 63 4l224 244c15 17 15 43 0 59l-229 250c-16 18-45 20-63 4-18-16-20-45-3-63l203-220-198-215z">
                        </path>
                    </g>
                </svg>
            </div>
        </div>
</template>

<script>
import { getLanguageArray, getLang } from "@/lang/index";
import { getSessionStorage, setSessionStorage } from "@/util/util";
export default {
    name: "TitleBar",
    componentName: "TitleBar",
    props: {
        titleText: {
            type: String,
            default: "",
        },
        titleVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedLang: "en",
            languageArray: getLanguageArray(),
        };
    },
    mounted() {
        // 优先取url参数上的语言，然后再取session中的语言，最后取浏览器语言
        let tempLang;
        let queryLang = this.$route.query["locale"];
        if (queryLang) {
            tempLang = queryLang;
            // 清除地址栏的参数
            const query = { ...this.$route.query }
            if (query.locale) {
                delete query.locale;
                this.$router.replace({ query });
            }
        } else {
            let sessionLang = getSessionStorage("langCur");
            if (sessionLang) {
                tempLang = getLang(sessionLang);
            } else {
                tempLang = getLang(navigator.language || navigator.userLanguage);
            }
        }
        this.selectedLang = getLang(tempLang);
        setSessionStorage("langCur", this.selectedLang);
        this.$i18n.locale = this.selectedLang;
    },
    methods: {
        onChangedLanguage() {
            if (this.selectedLang !== this.$i18n.locale) {
                setSessionStorage("langCur", this.selectedLang);
                this.$i18n.locale = this.selectedLang;
            }
        },
    }
};
</script>
<style lang="less" scoped>
.div_title {
    display: flex;
    justify-content: space-between;

    & .left {
        align-self: center;
        margin-left: 15px;
    }

    & .right {
        display: flex;
        text-align: right;
        align-self: center;
        align-items: center;
    }

    & select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-left: 2px;
        background: #f5f5f5;
        border: #f5f5f5;
        border: #f5f5f5;
    }

    & select:focus-visible {
        outline: none;
    }

    & select::-ms-expand {
        display: none;
    }
}
</style>