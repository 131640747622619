<template>
    <div class="dialog">
        <div class="dialog-content">
            <div style="float: right;">
                <van-icon name="close" size="20px" @click="cancelSetup()" />
            </div>
            <div class="div-title">{{ $t("app.setupPassword") }}</div>
            <div class="div-hint">{{ $t('app.setupPasswordRule') }}</div>
            <div class="div-body">
                <div class="div-row">
                    <div>{{ $t("app.newPassword") }}</div>
                    <password-field ref="inputPassword" v-model="password"
                        :placeholder="$t('app.newPasswordPlaceholder')" @enter="focusToRetypePassword()" />
                </div>
                <div class="div-row">
                    <div>{{ $t("app.retypePassword") }}</div>
                    <password-field ref="retypePassword" v-model="password2"
                        :placeholder="$t('app.retypePasswordPlaceholder')" @enter="submitSetup()" />
                </div>
                <div class="div-row">
                    <van-button type="primary" size="small" style="width: 100%" @click="submitSetup()">{{
                        $t("app.setupPasswordOk") }}</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordField from "@/components/PasswordField.vue";
import { saltPassword } from '@/util/md5';
import { setupPassword } from "@/api/api";

export default {
    name: "PasswordSetupDialog",
    componentName: "PasswordSetupDialog",
    props: {
        permit: "",
        user: "",
    },
    components: {
        PasswordField,
    },
    data() {
        return {
            password: "",
            password2: "",
        };
    },
    methods: {
        focusToInputPassword() {
            this.$refs.inputPassword.focus();
        },
        focusToRetypePassword() {
            this.$refs.retypePassword.focus();
        },
        submitSetup() {
            if (!this.password || this.password.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.newPasswordPlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToInputPassword();
                return;
            }
            if (!this.password2 || this.password2.trim().length === 0) {
                this.$toast({
                    message: this.$t("app.retypePasswordPlaceholder"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToRetypePassword();
                return;
            }
            if (this.password !== this.password2) {
                this.$toast({
                    message: this.$t("app.setupPasswordDiffer"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToRetypePassword();
                return;
            }
            if (this.password.length < 6 && this.password.length > 32) {
                this.$toast({
                    message: this.$t("app.setupPasswordRuleRefer"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToInputPassword();
                return;
            }
            let strongRegex = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,32}$/;
            if (!strongRegex.test(this.password)) {
                this.$toast({
                    message: this.$t("app.setupPasswordRuleRefer"),
                    className: "inexa",
                    position: "top",
                });
                this.focusToInputPassword();
                return;
            }

            var params = {
                setupPasswordPermit: this.permit,
                setupPasswordUser: this.user,
                newPassword: saltPassword(this.password),
            };
            setupPassword(params).then((rtn) => {
                // console.log("setupPassword : code=" + rtn.code + ", message=" + rtn.message);
                if (rtn.code === 200) {
                    this.visible = false;
                    this.$emit("submit", rtn.data);
                } else {
                    let msg = this.$t("app.err_" + rtn.code);
                    if (msg.startsWith("app.err_")) {
                        msg = this.$t("app.err_def");
                    }
                    this.$toast({
                        message: msg,
                        className: "inexa",
                        position: "top",
                    });
                    this.cancelSetup();
                }
            });
        },
        cancelSetup() {
            this.password = "";
            this.password2 = "";
            this.$emit("cancel");
        },
    },
};
</script>
<style lang="less" scoped>
.dialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.dialog-content {
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    width: 300px;
}
.div-title {
    width: 100%;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.div-body {
    padding: 0px 15px 15px 15px;
}
.div-hint {
    margin-top: 15px;
    border-radius: 3px;
    background-color: rgba(71, 65, 173, 0.05);
    padding: 5px 10px 5px 10px;
    font-size: var(--lumo-font-size-m);
    color: rgba(71, 65, 173, 1);
    white-space: pre-wrap;
    word-break: break-word;
}
.div-row {
    margin-top: 15px;
    margin-bottom: 0px;
}
.van-cell {
    margin-top: 2px;
    padding: 1px 5px;
}
.van-field {
    align-items: center;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #b2b2b2;
}
.van-field:hover:not(focus-within) {
    background-color: #f5f5f5;
    border: 1px solid #adadad;
}
.van-field:focus-within {
    outline: 1px solid #6e6bd7;
}
</style>