export default {
  app: {
    orgCode: "Konsernin/Brändin numero",
    orgCodePlaceholder: "Anna konsernin/brändin numero",
    username: "Tili",
    usernamePlaceholder: "Anna tili",
    password: "Salasana",
    passwordPlaceholder: "Anna salasana",
    captcha: "Vahvistuskoodi",
    captchaPlaceholder: "Anna vahvistuskoodi",
    captchaExpired: "Päivitä vahvistuskoodi.",
    login: "Kirjaudu sisään",
    remember: "Muista kirjautumistiedot",
    switchPC: "Vaihda hallintapaneeliin",
    setupPassword: "Aseta salasana",
    newPassword: "Uusi salasana",
    newPasswordPlaceholder: "Anna uusi salasana",
    retypePassword: "Anna uusi salasana uudelleen",
    retypePasswordPlaceholder: "Anna uusi salasana uudelleen",
    setupPasswordRule: "Salasanan on oltava 6-32 merkkiä ja sisältää vähintään kaksi seuraavista: numeroita, kirjaimia, symboleja.",
    setupPasswordRuleRefer: "Salasanan on oltava 6-32 merkkiä ja sisältää vähintään kaksi seuraavista: numeroita, kirjaimia, symboleja. Ole hyvä ja syötä uudelleen.",
    setupPasswordOk: "Vahvista",
    setupPasswordDiffer: "Salasanat eivät täsmää. Ole hyvä ja syötä uudelleen.",
    err_def: "Kirjautuminen epäonnistui. Päivitä sivu ja yritä uudelleen.",
    err_2000: "Virheellinen vahvistuskoodi.",
    err_2010: "Virheelliset kirjautumistiedot.",
    err_4001: "Tällä tilillä ei ole käyttöoikeutta mihinkään toimintoon.",
    err_4011: "Myymälä on poistettu käytöstä, kirjautuminen ei ole mahdollista.",
    err_4012: "Lisenssinhaltija on poistettu käytöstä, kirjautuminen ei ole mahdollista.",
    err_4013: "Brändi on poistettu käytöstä, kirjautuminen ei ole mahdollista.",
    err_4014: "Konserni on poistettu käytöstä, kirjautuminen ei ole mahdollista.",
    err_4015: "Alue on poistettu käytöstä, kirjautuminen ei ole mahdollista.",
    err_5001: "Salasanan asettaminen epäonnistui. Yritä uudelleen.",
  },
}