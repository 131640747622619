import Vue from 'vue'
Vue.filter('replaceText', function (value, first, second) {
  // console.log(first, 'first')
  let text = value.replace('{0}', first)
  // console.log(text, 'firstReplace')
  if (second) {
    text = text.replace('{1}', second)
  }
  // console.log(text, 'lastReplace')
  return text
})
